import { ClassDataGrid, ClassDataGridRow } from "../../utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../utils/CodeBlock/CodeBlock";
import React from "react";
import RouteTable from "../../../manifold/RouteTable";

export const ExampleContactMethodSharedModels = [
    {
        employmentType: "part_time",
        notes: "Applicant has been working part-time at this location for 2 years.",
    },
];

const propertiesGridData: ClassDataGridRow[] = [
    {
        name: "employmentType",
        typeLink: RouteTable.EnumTypes.Employment,
        type: "EnumEmploymentType",
        required: true,
        description: "The basis of the applicant's current employment.",
    },
    {
        name: "notes",
        type: "string",
        validation: "Must be between 5, and 1000 characters in length",
        required: false,
        description: "Any additional information regarding the applicant's current employment status.",
    },
];

const EmploymentModel: React.FunctionComponent = () => {
    return (
        <RcPage title="Employment" description="Sub Model">
            <RcSection title="Properties">
                <ClassDataGrid propertyList={propertiesGridData} />
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleContactMethodSharedModels[0]} />
            </RcSection>
        </RcPage>
    );
};

export default EmploymentModel;
