import { RcDataGrid, RcDataGridProps, RcText } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";

const columns: RcDataGridProps<ClassDataGridRow>["columns"] = [
    {
        name: "Name",
        title: "Name",
        width: 15,
        render: (e) => {
            return <RcText>{e.name}</RcText>;
        },
    },
    {
        name: "Type",
        title: "Type",
        width: 15,
        render: (e) => {
            const block = (
                <RcText>
                    <code>{e.type}</code>
                </RcText>
            );

            if (e.typeLink !== undefined) {
                return <Link to={e.typeLink}>{block}</Link>;
            }

            return block;
        },
    },
    {
        name: "Required",
        title: "Required",
        width: 20,
        render: (e) => {
            return (
                <>
                    <RcText>
                        <strong>{e.required ? "Required" : "Not-Required"}</strong>
                    </RcText>
                </>
            );
        },
    },
    {
        name: "Description",
        title: "Description",
        width: 50,
        render: (e) => {
            return (
                <>
                    <RcText>{e.description}</RcText>
                    {e.validation && (
                        <RcText>
                            <i>{e.validation}</i>
                        </RcText>
                    )}
                </>
            );
        },
    },
];

function parseRow(row: ClassDataGridRow) {
    return { name: row.name };
}

export interface ClassDataGridRow {
    name: string;
    type: string;
    typeLink?: string;
    required?: boolean;
    description: string;
    validation?: string;
}

interface Props {
    propertyList: ClassDataGridRow[];
}

export const ClassDataGrid: React.FunctionComponent<Props> = ({ propertyList }) => {
    return <RcDataGrid columns={columns} rows={parseRow} data={propertyList} />;
};
