import { RcPage, RcSection } from "@financeone/core-ui";
import React from "react";
import { Link } from "react-router-dom";
import RouteTable from "../../../manifold/RouteTable";
import { EnumDataGrid, EnumDataGridRow } from "../../utils/EnumDataGrid/EnumDataGrid";

const enumRows: EnumDataGridRow[] = [
    { value: "full_time", description: "Full time" },
    { value: "part_time", description: "Part Time" },
    { value: "casual", description: "Casual" },
    { value: "benefits", description: "Benefits" },
];

const EmploymentTypeEnum: React.FunctionComponent = () => {
    return (
        <RcPage title="Employment Types" description="Enumerated Types">
            <RcSection title="Description">
                <p>Values that are accepted for the Employment Type field.</p>
            </RcSection>
            <RcSection>
                <EnumDataGrid enumRows={enumRows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.SharedModelTypes.EmploymentModel}>Employment</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};

export default EmploymentTypeEnum;
