import { ClassDataGrid, ClassDataGridRow } from "../../utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import React from "react";
import RouteTable from "../../../manifold/RouteTable";

const gridData: ClassDataGridRow[] = [
    {
        name: "productSector",
        description: "Specifies the type of loan.",
        required: true,
        type: "EnumProductSector",
        typeLink: RouteTable.EnumTypes.ProductSector,
        validation: "Product Sector must be one of the following: 'consumer', 'commercial'",
    },
    {
        name: "loanAmount",
        type: "decimal",
        required: true,
        validation: "Must be between 1 to 250,000",
        description: "The requested value of the proposed loan.",
    },
    {
        name: "loanPurpose",
        type: "string",
        required: true,
        validation: "Maximum length cannot exceed 1,000 characters",
        description: "The primary purpose of the proposed loan.",
    },
    {
        name: "loanTerm",
        type: "int",
        validation: "Must be between 1, and 360",
        description: "The preferred term of the proposed loan in months.",
    },
    {
        name: "deposit",
        type: "decimal",
        required: false,
        validation: "Must be between 0 to 999,999",
        description: "The value of any deposits the applicant will make towards the proposed loan.",
    },
    {
        name: "contact",
        type: "ContactDetails",
        typeLink: RouteTable.SharedModelTypes.Contact,
        required: true,
        description: "The applicant's contact details.",
    },
    {
        name: "incomes",
        type: "Transaction",
        typeLink: RouteTable.SharedModelTypes.Transaction,
        required: false,
        description: "A summarized value and frequency of the applicant's sources of income.",
    },
    {
        name: "expenses",
        type: "Transaction",
        typeLink: RouteTable.SharedModelTypes.Transaction,
        required: false,
        description: "A summarized value and frequency of the applicant's expenses.",
    },
    {
        name: "notes",
        type: "string",
        required: false,
        validation: "Must be between 0, and 1,000 characters in length",
        description: "Any additional information regarding the referred applicant.",
    },
];

const ReferralModel: React.FunctionComponent = () => {
    return (
        <RcPage title="Referral Model" description="Referral Types">
            <RcSection title="Properties">
                <ClassDataGrid propertyList={gridData} />
            </RcSection>
        </RcPage>
    );
};

export default ReferralModel;
