import { ClassDataGrid, ClassDataGridRow } from "../../utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection, RcText } from "@financeone/core-ui";

import { CodeBlock } from "../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import RouteTable from "../../../manifold/RouteTable";
import React from "react";

const ExampleReferralModels = {
    productSector: "consumer",
    loanAmount: 10000,
    loanPurpose: "Applicant requires a new car to commute to work.",
    loanTerm: 36,
    deposit: 0,
    contact: {
        name: "John Citizen",
        phone: "1234567890",
        email: "example@financeone.com.au",
        employment: {
            type: "part_time",
            notes: "Applicant has been working part-time at this location for 2 years",
        },
        residential: {
            status: "renting",
            address: "49 Dalrymple Road, Garbutt, QLD, 4814",
            notes: "Applicant has lived at specified address for the past 6 months.",
        },
    },
    incomes: {
        totalValue: 123.45,
        frequency: "fortnightly",
        description: "description for incomes",
    },
    expenses: {
        totalValue: 123.45,
        frequency: "fortnightly",
        description: "description for expenses",
    },
    notes: "Applicant seeking pre-approval for specified amount.",
};

const ExampleMinimumReferralModels = {
    productSector: "consumer",
    loanAmount: 10000,
    loanPurpose: "Applicant requires a new car to commute to work.",
    contact: {
        name: "John Citizen",
        phone: "1234567890",
        email: "example@financeone.com.au",
        employment: {
            type: "part_time",
        },
        residential: {
            status: "renting",
        },
    },
    incomes: {
        totalValue: 123.45,
        frequency: "fortnightly",
    },
    expenses: {
        totalValue: 123.45,
        frequency: "fortnightly",
    },
};

const dataGridRows: ClassDataGridRow[] = [
    {
        name: "Token",
        type: "BearerToken",
        required: true,
        description: "The authenticaton token",
    },
    {
        name: "Content-Type",
        type: "application/json",
        required: true,
        description: "The accepted MIME type",
    },
];

export const ReferralRequest: React.FunctionComponent = () => {
    return (
        <RcPage title="Referral Requests">
            <RcSection>
                <RcText>
                    <p>Request the information needed to input into the necessary fields.</p>
                    <code>https://leadapi-sandbox.finone.net.au/api/referral/new</code>
                </RcText>
            </RcSection>
            <RcSection title="Headers">
                <ClassDataGrid propertyList={dataGridRows} />
            </RcSection>
            <RcSection title="Body">
                <CodeBlock code={ExampleReferralModels} />
            </RcSection>
            <RcSection title="Example Body" description="With Minimum Required Fields">
                <CodeBlock code={ExampleMinimumReferralModels} />
            </RcSection>
            <RcSection title="References">
                <RcText>
                    <ul>
                        <li>
                            <Link to={RouteTable.ProductSectorTypes.ReferralModel}>ReferralTypes</Link>
                        </li>
                    </ul>
                </RcText>
            </RcSection>
        </RcPage>
    );
};
