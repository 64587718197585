const RouteTable = {
    Dashboard: "/",
    ProductSectorTypes: {
        _root: "/product-type",
        ReferralModel: "/product-type/referral-model",
    },

    SharedModelTypes: {
        _root: "/shared-model",
        Contact: "/shared-model/contact",
        Transaction: "/shared-model/transaction",
        ResidentialModel: "/shared-model/residential-model",
        EmploymentModel: "/shared-model/employment-model",
    },

    EnumTypes: {
        _root: "/enum-type",
        Employment: "/enum-type/employment",
        ProductSector: "/enum-type/product-sector",
        ResidentialStatus: "/enum-type/residential-status",
        TransactionFrequency: "/enum-type/transaction-frequency",
    },

    HttpRequests: {
        _root: "/requests",
        ReferralRequest: "/referral-request",
    },
};

export default RouteTable;
