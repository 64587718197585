import { ClassDataGrid, ClassDataGridRow } from "../../utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../utils/CodeBlock/CodeBlock";
import React from "react";
import RouteTable from "../../../manifold/RouteTable";

export const ExampleContactMethodSharedModels = [
    {
        residentialStatus: "renting",
        address: "49 Dalrymple Road, Garbutt, QLD, 4814",
        notes: "Applicant has lived at specified address for the past 6 months.",
    },
];

const propertiesGridData: ClassDataGridRow[] = [
    {
        name: "residentialStatus",
        type: "EnumResidentialStatus",
        typeLink: RouteTable.EnumTypes.ResidentialStatus,
        required: true,
        description: "The current residential status of the applicant.",
    },
    {
        name: "address",
        type: "string",
        required: false,
        validation: "Must be between 0, and 100 characters in length",
        description: "The applicant's residential address.",
    },
    {
        name: "notes",
        type: "string",
        required: false,
        validation: "Must be between 0, and 1000 characters in length",
        description: "Any additional information regarding the applicant's current residential status.",
    },
];

const ResidentialModel: React.FunctionComponent = () => {
    return (
        <RcPage title="Residential" description="Sub Model">
            <RcSection title="Properties">
                <ClassDataGrid propertyList={propertiesGridData} />
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleContactMethodSharedModels[0]} />
            </RcSection>
        </RcPage>
    );
};

export default ResidentialModel;
