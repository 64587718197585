import { RcPage, RcSection } from "@financeone/core-ui";
import React from "react";
import { Link } from "react-router-dom";
import RouteTable from "../../../manifold/RouteTable";
import { EnumDataGrid, EnumDataGridRow } from "../../utils/EnumDataGrid/EnumDataGrid";

const enumRows: EnumDataGridRow[] = [
    { value: "consumer", description: "Consumer" },
    { value: "commercial", description: "Commercial" },
];

const ProductSectorEnum: React.FunctionComponent = () => {
    return (
        <RcPage title="Product Sector Types" description="Enumerated Types">
            <RcSection title="Description">
                <p>A list of accepted values for the Product Sector field.</p>
            </RcSection>
            <RcSection>
                <EnumDataGrid enumRows={enumRows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ProductSectorTypes.ReferralModel}>Referral</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};

export default ProductSectorEnum;
