import { RcPage, RcSection } from "@financeone/core-ui";
import React from "react";
import { Link } from "react-router-dom";
import RouteTable from "../../../manifold/RouteTable";
import { EnumDataGrid, EnumDataGridRow } from "../../utils/EnumDataGrid/EnumDataGrid";

const enumRows: EnumDataGridRow[] = [
    { value: "weekly", description: "Weekly" },
    { value: "fortnightly", description: "Fortnightly" },
    { value: "monthly", description: "Monthly" },
    { value: "annual", description: "Annual" },
];

const TransactionFrequencyEnum: React.FunctionComponent = () => {
    return (
        <RcPage title="Transactional Types" description="Enumerated Types">
            <RcSection title="Description">
                <p>A list of accepted values for Transaction frequency field.</p>
            </RcSection>
            <RcSection>
                <EnumDataGrid enumRows={enumRows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.SharedModelTypes.Transaction}>Transaction</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};

export default TransactionFrequencyEnum;
