import { ClassDataGrid, ClassDataGridRow } from "../../utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../utils/CodeBlock/CodeBlock";
import React from "react";
import RouteTable from "../../../manifold/RouteTable";

export const ExampleContactMethodSharedModels = [
    {
        name: "John Smith",
        phone: "1234567890",
        email: "example@financeone.com.au",
    },
];

const propertiesGridData: ClassDataGridRow[] = [
    {
        name: "name",
        type: "string",
        required: true,
        validation: "Must be between 0, and 100 characters in length",
        description: "The name of the referred applicant.",
    },
    {
        name: "phone",
        type: "string",
        required: true,
        validation: "Must be between 6, and 10 characters in length",
        description: "The phone number of the referred applicant.",
    },
    {
        name: "email",
        type: "string",
        required: true,
        validation: "Must be no more than 100 characters in length",
        description: "The email address of the referred applicant.",
    },
    {
        name: "employment",
        typeLink: RouteTable.SharedModelTypes.EmploymentModel,
        type: "Employment",
        required: false,
        description: "A short summary of the referred applicant's employment status.",
    },
    {
        name: "residential",
        typeLink: RouteTable.SharedModelTypes.ResidentialModel,
        type: "Residential",
        required: false,
        description: "A short summary of the referred applicant's residential status.",
    },
];

const ContactMethodModel: React.FunctionComponent = () => {
    return (
        <RcPage title="Contact Details" description="Sub Model">
            <RcSection title="Properties">
                <ClassDataGrid propertyList={propertiesGridData} />
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleContactMethodSharedModels[0]} />
            </RcSection>
        </RcPage>
    );
};

export default ContactMethodModel;
