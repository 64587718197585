import { RcDataGrid, RcDataGridColumn, RcText } from "@financeone/core-ui";
import React from "react";

export interface EnumDataGridRow {
    value: string;
    description: string;
}

interface Props {
    enumRows: EnumDataGridRow[];
}

const columns: RcDataGridColumn<EnumDataGridRow>[] = [
    {
        name: "Value",
        title: "Value",
        width: 15,
        render: (e) => {
            return (
                <RcText>
                    <code>{e.value}</code>
                </RcText>
            );
        },
    },
    {
        name: "Description",
        title: "Description",
        width: 15,
        render: (e) => {
            return <RcText>{e.description}</RcText>;
        },
    },
];

function parseRow(row: EnumDataGridRow) {
    return { name: row.value };
}

export const EnumDataGrid: React.FunctionComponent<Props> = ({ enumRows }) => {
    return <RcDataGrid columns={columns} rows={parseRow} data={enumRows} />;
};
