import { RcAlert, RcApplication, RcApplicationPage, RcBadge, RcButton, RcIconType, RcPage, RcPanel, RcPanelLine, RcSpacer, RcStack, RcTitle, THEME_DEFAULT, ThemeLoad } from "@financeone/core-ui";
import { RcApplicationPageMenuGroup, RcApplicationPageMenuItem, RcApplicationPageMenuProps, RcApplicationPageMenuSubItem } from "@financeone/core-ui/build/components/application-page/application-page-menu";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router";

import ContactMethodModel from "./screens/SharedModels/ContactMethodModel";
import Dashboard from "./screens/DashBoard";
import EmploymentModel from "./screens/SharedModels/EmploymentModel";
import EmploymentType from "./screens/Enums/EmploymentTypeEnum";
import ProductSectorEnum from "./screens/Enums/ProductSectorEnum";
import ReferralModel from "./screens/ReferralModel/ReferralModel";
import { ReferralRequest } from "./screens/HttpRequests/ReferralRequest";
import ResidentialModel from "./screens/SharedModels/ResidentialModel";
import ResidentialStatusEnum from "./screens/Enums/ResidentialStatusEnum";
import RouteTable from "../manifold/RouteTable";
import TransactionFrequencyEnum from "./screens/Enums/TransactionFrequencyEnum";
import TransactionModel from "./screens/SharedModels/TransactionModel";

export const App: React.FunctionComponent = () => {
    const [showSupportModal, setShowSupportModal] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem("app_theme") || "default");

    const location = useLocation();

    useEffect(() => {
        const storedTheme = localStorage.getItem("app_theme");

        if (storedTheme === "dark") {
            ThemeLoad({
                name: "dark",
                title: "dark",
                colours: {
                    background: "#111",
                    foreground: "#888",
                    primary: "#111",
                },
            });
        }
    }, []);

    function toggleTheme() {
        const storedTheme = localStorage.getItem("app_theme") || "default";

        if (storedTheme === "default") {
            ThemeLoad({
                name: "dark",
                title: "dark",
                colours: {
                    background: "#111",
                    foreground: "#888",
                    primary: "#111",
                },
            });
            localStorage.setItem("app_theme", "dark");
            setTheme("dark");
        } else {
            ThemeLoad(THEME_DEFAULT);
            localStorage.removeItem("app_theme");
            setTheme("default");
        }
    }

    const commandBar = (
        <RcStack orientation="vertical" grow horizontalAlign="stretch">
            <RcStack orientation="horizontal" grow>
                <img src={theme === "default" ? "/logo-coloured.svg" : "/logo.svg"} height="40" alt="Finance one" />
                <RcSpacer orientation="horizontal" size="large" />
                <RcStack orientation="vertical" grow horizontalAlign="stretch">
                    <RcTitle
                        title="Referral API Documentation"
                        nav={
                            <>
                                <RcButton fill="empty" icon={theme === "default" ? "Moon" : "Sun"} onClick={() => toggleTheme()} />
                                <RcButton variant="primary" onClick={() => setShowSupportModal(true)} icon="Phone">
                                    API Support
                                </RcButton>
                            </>
                        }
                    />
                </RcStack>
            </RcStack>
        </RcStack>
    );

    // Menu bar - To edit the menu items, do so within this function
    function getMenuNav() {
        function generateMenuGroup(key: string, title: string | undefined, icon: RcIconType | undefined, items: RcApplicationPageMenuItem[]): RcApplicationPageMenuGroup {
            const label = { icon: icon, title: title };
            return { key: key, label: label, items: items };
        }

        function generateMenuItem(title: string, subitems?: RcApplicationPageMenuSubItem[], icon?: RcIconType, to?: { link: string; clickable: boolean }, badge?: React.ReactNode): RcApplicationPageMenuItem {
            const active = !!to ? (to.link === "/" ? location.pathname === "/" : location.pathname.indexOf(to.link) > -1) : false;
            return { title: title, subItems: subitems, icon: !!icon ? icon : "ListOrdered", key: title, to: !!to ? (to.clickable ? to.link : undefined) : undefined, active: active, badge: badge };
        }

        function generateSubMenuItem(link: string, title: string, icon: RcIconType): RcApplicationPageMenuSubItem {
            const active = location.pathname === link;
            return { to: link, title: title, icon: icon, key: `${title}${link}`, active: active };
        }

        const navNew: RcApplicationPageMenuProps["groups"] = [
            generateMenuGroup("TopItem", undefined, undefined, [generateMenuItem("Getting Started", undefined, "Home", { link: RouteTable.Dashboard, clickable: true })]),

            generateMenuGroup("Models", "Models", undefined, [
                generateMenuItem("Referral", undefined, "UserAdd", { link: RouteTable.ProductSectorTypes.ReferralModel, clickable: true }),
                generateMenuItem(
                    "Referral Sub Models",
                    [
                        generateSubMenuItem(RouteTable.SharedModelTypes.Contact, "Contact", "User"),
                        generateSubMenuItem(RouteTable.SharedModelTypes.EmploymentModel, "Employment", "Briefcase"),
                        generateSubMenuItem(RouteTable.SharedModelTypes.ResidentialModel, "Residential", "Home"),
                        generateSubMenuItem(RouteTable.SharedModelTypes.Transaction, "Transaction", "ExchangeDollar"),
                    ],
                    "ListOrdered",
                    { link: RouteTable.SharedModelTypes._root, clickable: false }
                ),
            ]),
            generateMenuGroup("HttpRequest", "Http Request", undefined, [generateMenuItem("Referral Request", undefined, "UserAdd", { link: RouteTable.HttpRequests.ReferralRequest, clickable: true }, <RcBadge variant="warning">POST</RcBadge>)]),
            generateMenuGroup("References", "References", undefined, [
                generateMenuItem(
                    "Enumerated Values",
                    [
                        generateSubMenuItem(RouteTable.EnumTypes.Employment, "Employment", "Briefcase"),
                        generateSubMenuItem(RouteTable.EnumTypes.ProductSector, "Product Sector", "Package"),
                        generateSubMenuItem(RouteTable.EnumTypes.ResidentialStatus, "Residential Status", "Home"),
                        generateSubMenuItem(RouteTable.EnumTypes.TransactionFrequency, "Transaction Frequency", "Calendar"),
                    ],
                    "ListOrdered",
                    { link: RouteTable.EnumTypes._root, clickable: false }
                ),
            ]),
        ];

        return navNew;
    }

    return (
        <RcApplication>
            <RcApplicationPage commandBar={commandBar} navigation={getMenuNav()}>
                <Routes>
                    <Route path={RouteTable.Dashboard} element={<Dashboard />} />
                    <Route path={RouteTable.ProductSectorTypes.ReferralModel} element={<ReferralModel />} />
                    <Route path={RouteTable.SharedModelTypes.Contact} element={<ContactMethodModel />} />
                    <Route path={RouteTable.SharedModelTypes.Transaction} element={<TransactionModel />} />
                    <Route path={RouteTable.SharedModelTypes.ResidentialModel} element={<ResidentialModel />} />
                    <Route path={RouteTable.SharedModelTypes.EmploymentModel} element={<EmploymentModel />} />
                    <Route path={RouteTable.EnumTypes.Employment} element={<EmploymentType />} />
                    <Route path={RouteTable.EnumTypes.ProductSector} element={<ProductSectorEnum />} />
                    <Route path={RouteTable.EnumTypes.TransactionFrequency} element={<TransactionFrequencyEnum />} />
                    <Route path={RouteTable.EnumTypes.ResidentialStatus} element={<ResidentialStatusEnum />} />
                    <Route path={RouteTable.HttpRequests.ReferralRequest} element={<ReferralRequest />} />
                    <Route
                        element={
                            <RcPage>
                                <RcAlert icon="CloseCircle" variant="danger" title="The Page Could Not Be Found">
                                    <p>What was here is either not here anymore or never was.</p>
                                </RcAlert>
                            </RcPage>
                        }
                    />
                </Routes>
            </RcApplicationPage>
            {showSupportModal && (
                <RcPanel onClose={() => setShowSupportModal(false)} icon="Phone" title="API Support">
                    <h3 className="rc-h3">Finance One Support</h3>
                    <p>If you require assistance, please use the following contact methods:</p>
                    <div className="rc-mt-2 rc-mb-2">
                        <RcPanelLine title="Finance One" body={<a href="tel://1800346663">1800 346 663</a>} />
                        <RcPanelLine title="API Support Email" body={<a href="mailto:api@financeone.com.au">api@financeone.com.au</a>} />
                    </div>
                </RcPanel>
            )}
        </RcApplication>
    );
};
