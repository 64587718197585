import { ClassDataGrid, ClassDataGridRow } from "../../utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../utils/CodeBlock/CodeBlock";
import React from "react";
import RouteTable from "../../../manifold/RouteTable";

export const ExampleContactMethodSharedModels = [
    {
        totalValue: "1000.00",
        transactionFrequency: "weekly",
        notes: "Additional notes regarding applicant incomes/expenses.",
    },
];

const propertiesGridData: ClassDataGridRow[] = [
    {
        name: "totalValue",
        type: "decimal",
        validation: "Must be between 1, and 250,000",
        required: false,
        description: "The total value of an applicant's incomes/expenses.",
    },
    {
        name: "transactionFrequency",
        type: "EnumTransactionFrequency",
        typeLink: RouteTable.EnumTypes.TransactionFrequency,
        validation: "Must be between 5, and 150 characters in length.",
        required: true,
        description: "The frequency of the transaction.",
    },
    {
        name: "description",
        type: "string",
        validation: "Must be between 5, and 1000 characters in length",
        required: false,
        description: "Any additional notes regarding the applicant's incomes/expenses.",
    },
];

const TransactionModel: React.FunctionComponent = () => {
    return (
        <RcPage title="Transaction" description="Sub Model">
            <RcSection title="Properties">
                <ClassDataGrid propertyList={propertiesGridData} />
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleContactMethodSharedModels[0]} />
            </RcSection>
        </RcPage>
    );
};

export default TransactionModel;
